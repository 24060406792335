:root {
    /* CSS HEX */
    --red-salsa: #f94144ff;
    --orange-red: #f3722cff;
    --yellow-orange-color-wheel: #f8961eff;
    --mango-tango: #f9844aff;
    --maize-crayola: #f9c74fff;
    --pistachio: #90be6dff;
    --zomp: #43aa8bff;
    --cadet-blue: #4d908eff;
    --queen-blue: #577590ff;
    --cg-blue: #277da1ff;


    --charcoal: #264653ff;
    --persian-green: #2a9d8fff;
    --orange-yellow-crayola: #e9c46aff;
    --sandy-brown: #f4a261ff;
    --burnt-sienna: #e76f51ff;

    --charcoal: #233d4dff;
    --pumpkin: #fe7f2dff;
    --sunglow: #fcca46ff;
    --olivine: #a1c181ff;
    --polished-pine: #619b8aff;

}

body {
    margin: 0;
    /*background: #9a9aa2;*/
    background: #9a9aa2;
    color: #606063;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

html, body {
    height: 100%;
    max-height: 100%;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*cursor:pointer;*/
}

.mobileBody {
    touch-action: none;
}

/* override bootstrap settings */

/*h2 {*/
/*  margin-top: 1.2em;*/
/*  font-size: 1.3em;*/
/*}*/

/*h3 {*/
/*  margin-top: 1.2em;*/
/*  font-size: 1.2em;*/
/*}*/

/*h4 {*/
/*  font-size: 1.1em;*/
/*}*/

.observer {
    background-color: #2a9d8f;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#canvas-container {
    width: 100vw;
    height: 100vh;
}

canvas,
.canvas > div {
    z-index: 1;
}

.loading {
    padding: 10px;
    transform: translate3d(-50%, -50%, 0);
}


.log {
    position: fixed;
    top: -50vh;
    left: -50vw;
    width: 40vw;
    height: 100vh;
    font-size: 12px;
    color: white;
    padding: 10px;
    margin-left: 10vw;
    margin-right: 10vw;
    /*background-color: rgb(43, 52, 96, 0.6);*/
    background-color: rgb(43, 43, 43, 0.6);
}

.levaGUI {
    position: fixed;
    z-index: 4000;
    top: 2px;
    left: 90px;
    width: 300px;
    /*height: 100vh;*/
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
}

.fullscreenwrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  /*overflow: hidden;*/
  /*z-index: 3;*/
}

.rootcontainer {
  position: absolute;
  top: 0;
  left: 0;
  /* change canvas height below */
  height: 100vw;
  width: 100vw;
  overflow: hidden;
  /*padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);*/
}

.zsunburstbackground {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    zIndex: 0;
}

.zsunburstcontainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

.sunburstSearchConatiner {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.sunburst {
    z-index: 101;
    /*z-index: 16522223;*/
    /*position:fixed;*/
    /*top: -415px;*/
    /*right: -605px;*/
    width: 50vw;
    height: 40vw;
}


.searchcontainer {
    position: absolute;
    padding: 2vw;
    left: 100vw;
    width: 45vw;
    height: 15vh;
    top: 82vh;
    z-index: 101;
    pointer-events: none;
    /*background-color: rgba(230, 250,250, 0.5);*/
}

.fullscreenModal {
    position: absolute;
    color: white;
    z-index: 103;
}

.fullscreenModalFirstload {
    position: absolute;
    color: white;
    z-index: 103;
}

.fulloverlayModal{
    position: absolute;
    z-index: 103;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.7;
}

.formcontainer {
    pointer-events: auto;
    /*margin-top: 80vh;*/
    padding: 0vw;
}


.threeD {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.dummy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
}

.splashcontainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    overflow: hidden;
    background-color: white;
    /*z-index: 106;*/
    z-index: 2000;
}

.onboardingcontainer {
    position: fixed;
    display: flex;
    height: auto;
    flex-direction: column;
    /*border: 1px solid black;*/
    background-color: #4A4B59DD;
    color: white;
    z-index: 300;
    padding: 2vmax;
    border-radius: 15px;
    font-size: 1.2em;
    }

.triangle-down {
    position: fixed;
    width: 1vw;
    height: auto;
    border-left: 30px solid transparent;
    border-right: 5px solid transparent;
    /*border-top: 2vh solid red;*/
    z-index: 3;
}


.buttonright {
    background-color: lightgray;
    border: 1px solid darkgrey;
    color: black;
    border-radius: 5px;
    padding: 5px;
    width: auto;
    margin-left: auto;
}

.buttonright:hover {
    background-color: white;
    cursor: pointer;
}

.Xbuttonright {
    /*background-color: lightgray;*/
    /*border: 1px solid darkgrey;*/
    color: white;
    /*border-radius: 5px;*/
    padding: 0px;
    width: auto;
    margin-left: auto;
}

.Xbuttonright:hover {
    /*background-color: white;*/
    cursor: pointer;
}


.onboardingText p {
    padding: 0px;
    margin: 0.5em;
}

.jumpControl {
    z-index: 100;
    background-color: red;
}

.header {
    position: absolute;
    width: 70vw;
    top: 0;
    z-index: 101;
    padding-left: 0.1vw;
    display: flex;
    flex-direction: row;
    /*align-content: flex-start;*/
    align-items: baseline;
}

.headerbuttons {
    /*display: flex;*/
    /*align-content: flex-start;*/
    /*align-items: bottom;*/
    /*height: 6vh;*/
    /*padding-bottom: 2vw;*/
    padding-left: 2.5vw;
    /*margin-bottom: 0.5em;*/
}

.headerbuttons:hover {
    cursor: pointer;
    color: #F5092D;

}

.headeritem {
    font-size: 2.5vmax;
}

.headeritem:hover {
    cursor: pointer;
}

.headericon {
    top: 2vm;
    /*height: 1vh;*/
}

.storySelector {
    position: relative;
    color: black;
    background-color: rgba(211, 211, 211, 0.7);
    /*border: blue 1px;*/
    padding: 4px 10px 10px 10px;
    /*top: 1vh;*/
    margin: 5px;
    /*left: 10px;*/
    font-size: 2vh;
    width: 100%; /* Take full width of parent */
    min-width: max-content; /* Ensure it's at least as wide as the content */
    box-sizing: border-box; /* Include padding in width calculation */
}

.storySelector:hover {
    cursor: pointer;
    background-color: rgba(211, 211, 211, 0.9);
    color: rgb(157, 9, 33);
    font-weight: 500;
}

.storyImage {
    padding: 7px 2px 2px 2px;
    pointer-events: none;
    margin: auto;
    display: block;
    opacity: 1.0;
    width: 10em;
}


.footer {
    position: absolute;
    width: 98vw;
    bottom: 0.4vw;
    text-align: right;
    /*background-color: rgba(108,108, 108, 0.60);*/
    font-size: 2.5vm;
    color: lightgray;
    opacity: 1;
    z-index: 102;
}

.footeritem {
    background-color: rgba(108, 108, 108, 0.40);
}

.footeritem:hover {
    color: white;
    cursor: pointer;
}

.testbutton {
    color: lightgray;
    font-size: 0.7em;
}

.testbutton:hover {
    color: white;
    cursor: pointer;
}


.fulloverlay1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    /*right: -500px;*/
    overflow: hidden;
    background-color: black;
    /*z-index: 106;*/
    z-index: 2001;
    opacity: 0.9;
}

.fulloverlay2 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    z-index: 2001;
}


.transparentoverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    /*background-color: #ff00ff33;*/
    /*z-index: 106;*/
    /*opacity: 0.5;*/
    /*color: blue;*/
    z-index: 2002;
}


.closeX {
    /*position: -webkit-sticky; !* for Safari *!*/
    position: absolute;
    /*align-self: flex-end; !* <-- this is the fix *!*/
    /*padding-right: 20px;*/
    /*padding-left: 90%;*/
    left: 90%;
    /*padding-left: 10px;*/
    top: 0px;
    /*width: 16px;*/
    /*height: 16px;*/
    opacity: 0.5;
    color: white;
    cursor: pointer;
    font-size: 2em;
    /*font-weight: bolder;*/
}

.closeX:hover {
    opacity: 1;
}

.closeXflex {
    line-height: 0;
    margin: 4px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    color: white;
    cursor: pointer;
    font-size: 2.5em;
    /*font-weight: bolder;*/
}

.closeXflex:hover {
    opacity: 1;
}


.closeXX {
    /*position: -webkit-sticky; !* for Safari *!*/
    position: absolute;
    /*align-self: flex-end; !* <-- this is the fix *!*/
    /*margin-right: 30px;*/
    /*padding-left: 90%;*/
    left: 90%;
    padding-left: 10px;
    top: 0px;
    width: 24px;
    height: 24px;
    opacity: 0.7;
    color: white;
    cursor: pointer;
    font-size: 3em;
    /*font-weight: bolder;*/
}

.closeXX:hover {
    opacity: 1;
}

.tourContainerMeta {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
}


.tourContainer {
    display: flex;
    /*flex-direction: row;*/
    align-content: flex-start;
    align-items: stretch;
    width: 100%; /* Ensure container takes full width */
}

.closeTour {
    font-size: 15px;
    /*align-self: end;*/
    cursor: pointer;
    width: 15px;
    margin-top: 5px;
    margin-left: 10px;
    /*position: absolute;*/
    text-align: center;
    z-index: 10000;
    color: black;
    font-weight: 700;
}

.closeTour:hover {
    font-weight: 900;
    background-color: lightgray;
}

.trunc2 {
    /*overflow: hidden;*/
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 1;*/
    /*-webkit-box-orient: vertical;*/

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 18vw;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 0%;
    margin-left: -1vw;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.panelhover:hover {
    /*pointer-events: auto;*/
}

.trackerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /*width: 100%;*/
}


.impressum {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding-right: 20%;
    margin-left: 10%;
    color: #333333;
    font-size: 1.1em;
}
.impressum a {
    color: #333333;
}
.impressum h4 {
    display: block;
    margin-block-start: 1.23em;
    margin-block-end: 0.33em;
}

.logo1 {
    padding: 1vw;
}

.logo2 {
    padding: 1vw;
}
