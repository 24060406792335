:root {
    --audiocontrols: #e1e1e1;
}

.audiowrapper {
    padding: 15px 20px 40px 20px;
}

.rhap_container, .rhap_container *:focus {
    outline: none
}

.rhap_container {
    background-color: rgba(1,0,0,0.13);
}

.rhap_time {
    color: var(--audiocontrols);
    font-size: 0.8em;
}

.rhap_main-controls-button {
    color: var(--audiocontrols);
    font-size: 25px;
    height: 25px;
    width: 25px;
}

.rhap_volume-button {
    color: var(--audiocontrols);
    font-size: 20px;
    height: 20px;
    width: 40px;
}

.rhap_volume-indicator {
    background: var(--audiocontrols);
}

.rhap_progress-indicator {
    background: var(--audiocontrols);
    width: 16px;
    height: 16px;
    margin-left: -8px;
    top: -5px;
}

.rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 20px;
}


.rhap_volume-container {
    margin-left: 10px;
}
