
.helpContainer {
    position: absolute;
    color: #e1e1e1;
    width: 30vw;
    font-size: 1.6em;
}

.helpTimeline {
    position: absolute;
    bottom: 7vh;
    left: 88vw;
    width: auto;
    color: #e1e1e1;
    display: flex;
    /*flex-direction: row;*/
    flex-flow: column nowrap;
    /*align-items: self-start;*/
}

.helpSunburst {
    position: absolute;
    bottom: 1vh;
    width: auto;
    height: auto;
    color: rgb(96,96,96);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    font-size: 1.3em;
    z-index: 1001;
}


.helpnext {
    width: auto;
    font-size: 1.9em;
    color: lightgray;
    align-self: self-end;
}

.helpnext:hover {
    cursor: pointer;
    color: white;
}
