.trackerContainer {
    /*position: absolute;*/
    /*bottom: 15vh;*/
    /*left: 85vw;*/
    /*display: flex;*/
    z-index: 30001;
    opacity: 1;
    color: white;
    /*background-color: coral;*/
}

.trackcircle:hover {
    cursor: pointer;
}
