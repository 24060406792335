.gallerycontainer{
    /*background-color: lightgrey;*/
}

.scrollContainer{
    /*width: calc(100% - 90px);*/
    /*width: calc(90% - 20px);*/
    /*set width in gallery.js*/
    height: 100%;
    overflow-x: hidden;
}

.imgtitle {
    /*font-family: 'abel';*/
}

.gridheader {
    padding-left: 5px;
}

/* [1] The container */
.img-hover-zoom {
    /*height: 300px; !* [1.1] Set it as per your need *!*/
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
    transform: scale(1.3);
}

.img:-moz-loading {
    visibility: hidden;
}

/* for compatibility with main page */
a:hover {
    color: #000000;
    text-decoration: none;
}


/* full view */
/* unvisited link */
a.fullview:link {
    color: #b0b0b0 !important;
    text-decoration: underline;
}

/* visited link */
a.fullview:visited {
    color: grey !important;
}

/* mouse over link */
a.fullview:hover {
    color: #e4e8d0 !important;
    text-decoration: underline;
}

/* selected link */
a.fullview:active {
    color: grey !important;
}

.resetGalleryFilterContainer {
    /*white-space: nowrap;*/
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.resetGalleryFilterBox{
    display: flex;
    align-items: baseline;
    /*flex-wrap: nowrap;*/
    background-color: white;
    box-sizing: border-box;
    border:2px solid #fa7272;
    border-radius: 10px;
    font-size: 0.8em;
    cursor: pointer;
    margin: 2px;
    padding: 0px;
    padding-left: 5px;
    max-width: 30vw;
    min-width: 3vw;
}
.resetGalleryFilterString{
    /*flex-shrink: 1;*/
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resetGalleryFilter{
    /*flex-shrink: 1;*/
    background-color: #fa7272;
    box-sizing: border-box;
    border:1px solid #fff;
    border-radius: 20px;
    font-size: 0.7em;
    font-weight: bold;
    color:white;
    width: auto;
    cursor: pointer;
    margin: 2px;
    padding: 2px;
}

.resetGalleryFilter:hover{
    background-color: red;
}


/* ---- grid ---- */
.grid {
    max-width: 100%;
    padding: 0.19em;
    /*margin:0 auto;*/
}
/* ---- grid-item ---- */
.grid-sizer{
    width: calc(33% - 5px);
}
.grid-item {
    float: left;
    padding: 7px 7px 7px 2px;
}

.grid-item img {
    /*width: 150px;*/
}



/*!* Effect 4: fall perspective *!*/
/*.grid.effect-4 {*/
/*    perspective: 1300px;*/
/*}*/

/*.grid.effect-4 li {*/
/*    transform-style: preserve-3d;*/
/*}*/

/*.grid.effect-4 li.animate {*/
/*    transform: translateZ(400px) translateY(300px) rotateX(-90deg);*/
/*    animation: fallPerspective .8s ease-in-out forwards;*/
/*}*/

/*@keyframes fallPerspective {*/
/*    100% { transform: translateZ(0px) translateY(0px) rotateX(0deg); opacity: 1; }*/
/*}*/


.card-columns {
    column-count:8;
}

/*@media (min-width: 300px) {*/
/*    .card-columns {*/
/*        column-count: 2;*/
/*    }*/
/*    .grid-item img {*/
/*        width: 120px;*/
/*    }*/
/*}*/


/*@media (min-width: 576px) {*/
/*    .card-columns {*/
/*        column-count: 2;*/
/*    }*/
/*    .grid-item img {*/
/*        width: 120px;*/
/*    }*/
/*}*/

/*@media (min-width: 768px) {*/
/*    .card-columns {*/
/*        column-count: 2;*/
/*    }*/
/*    .grid-item img {*/
/*        width: 150px;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .card-columns {*/
/*        column-count: 4;*/
/*    }*/
/*    .grid-item img {*/
/*        width: 150px;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .card-columns {*/
/*        column-count: 5;*/
/*    }*/
/*}*/


ul {
    margin-top: -0.2em;
    list-style: none;
    /*margin:0 auto;*/
}

#GalleryGrid {
    /*height: 600px;*/
    /*width: 700px;*/
}



/* Lazy loading */

.placeholder {
    background: #f8f8f8;
    height: 100%;
}


/*.fade-appear {*/
/*    opacity: 0.01;*/
/*}*/

/*.fade-appear.fade-appear-active {*/
/*    opacity: 1;*/
/*    transition: opacity .5s ease-in;*/
/*}*/

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    /*margin-top: 50%;*/
    /*margin-bottom: 50%;*/
    left: 20%;
    width: 80px;
    height: 80px;
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #bfbfbf;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
