
.formcontainer input[type=text] {
    width: 70%;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    font-size: 0.85em;
    background-size: 1em;
    background-color: white;
    background-image: url('../searchicon.png');
    background-position: 10px 5px;
    background-repeat: no-repeat;
    padding: 5px 20px 5px 40px;
    margin: 0px;
}

.select {
    width: 70%;
    flex: 5 1 auto;
    font-size: 0.9em;
    padding: 5px 10px 5px 0px;
}

select:default {
    text-decoration: coral;
    opacity: 1 !important;
}

.resetFilter {
    background-color: #fa7272;
    box-sizing: border-box;
    border: 0px solid #fff;
    border-radius: 20px;
    color: white;
    cursor: pointer;
}

.resetFilter:hover {
    background-color: red;
}


/*input[id^='react-'] {*/
/*    opacity: 1 !important;*/
/*    z-index: 20;*/
/*}*/

/*div {*/
/*    opacity: 1 !important;*/
/*    background-color: orangered;*/
/*    z-index: 20;*/
/*}*/


/*input {*/
/*    opacity: 1 !important;*/
/*    z-index: 20 !important;*/
/*}*/


/*.verticalStory {*/
/*    z-index: 100;*/
/*    position:fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    !*right: -10vh;*!*/
/*    width: 33vw;*/
/*    height: 100vh;*/
/*    color: white;*/
/*    padding: 0px 10px 10px 10px;*/
/*    margin-left: 0;*/
/*    margin-right: 10vw;*/
/*    overflow: auto;*/
/*    font-size: 0.8vw;*/
/*}*/

/*@media (min-width: 576px) {*/
/*    .verticalStory {*/
/*        font-size: 1.5vw;*/
/*    }*/
/*    .figureCaption {*/
/*        font-size: 1.2vw;*/
/*    }*/

/*}*/

/*@media (min-width: 768px) {*/
/*    .verticalStory {*/
/*        font-size: 1.2vw;*/
/*    }*/
/*    .figureCaption {*/
/*        font-size: 0.9vw;*/
/*    }*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .verticalStory {*/
/*        font-size: 1.2vw;*/
/*    }*/
/*    .figureCaption {*/
/*        font-size: 0.9vw;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .verticalStory {*/
/*        font-size: 1vw;*/
/*    }*/
/*    .figureCaption {*/
/*        font-size: 0.9vw;*/
/*    }*/
/*}*/

/*@media (min-width: 1800px) {*/
/*    .verticalStory {*/
/*        font-size: 0.8vw;*/
/*    }*/
/*    .figureCaption {*/
/*        font-size: 0.75vw;*/
/*    }*/
/*}*/



