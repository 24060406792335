.link{
    cursor: pointer;
    text-decoration: underline;
}

a{
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 6px;
}

.itemsImage{
    flex-basis: 3vw;
    flex-grow: 8;
    cursor: pointer;
    /*margin-right: 1vw;*/
    transition: padding 0.5s ease;
    padding: 10px;
}

.itemsImage:active{
    padding: 100px;
}

.imagefwd {
    flex-basis: 2vw;
    flex-shrink: 2;
    cursor: pointer;
    font-size: 2vw;
    color: #a2a2a2;
    margin-right: 0.6vw;
}

.imageback {
    flex-basis: 2vw;
    flex-shrink: 2;
    cursor: pointer;
    font-size: 2vw;
    color: #a2a2a2;
    margin-left: 0.5vw;
}

.imageback:hover, .imagefwd:hover {
    color: white;
}

.descriptiveText{
    display:inline-block;
    padding-top: 6px;
}

.label{
    color: white;
}

.popuptext{
    margin-left: 10px;
    margin-right: 10px;
    /*width: 350px;*/
    /*// set height to same height as horizontal height*/
    /*  height: 300px;*/
}


.closeX {
    /*position: -webkit-sticky; !* for Safari *!*/
    position: absolute;
    /*align-self: flex-end; !* <-- this is the fix *!*/
    padding-right: 20px;
    /*padding-left: 90%;*/
    left: 90%;
    padding-left: 10px;
    top: 0px;
    width: 16px;
    height: 16px;
    opacity: 0.5;
    color: white;
    cursor: pointer;
    font-size: xx-large;
    /*font-weight: bolder;*/
}
.closeX:hover {
    opacity: 1;
}




.horizontal {
    z-index: 100;
    position:fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    top: 0vh;
    /*left: 3vw;*/
    width: 80vw;
    height: 27vh;
    color: white;
    padding: 0px 10px 10px 10px;
    margin-left: 10vw;
    margin-right: 10vw;
    overflow: auto;
    font-size: 0.85em;
}

.vertical {
    z-index: 100;
    position:fixed;
    top: 0;
    /*left: -8vw;*/
    left: -10vw;
    /*right: -10vh;*/
    width: 30vw;
    height: 100vh;
    color: white;
    padding: 0px 10px 10px 10px;
    margin-left: 10vw;
    margin-right: 10vw;
    overflow: auto;
    font-size: 0.9vw;
}



.screenoverlay {
    /*position: fixed;*/
    /*z-index: 16522223;*/
    z-index: 1652;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.6s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    opacity: 0;
    animation-name: fadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 200ms;
}

@keyframes fadeOutOpacity {
    20% {
        opacity: 0.3;
    }
    100% {
        opacity: 0;
    }
}



.firstcontainer {
    padding-top: 15px;
    flex-grow: 3;
    flex-shrink: 3;
}

.secondcontainer {
    padding-top: 15px;
    flex-grow: 3;
    flex-shrink: 3;
    /*display:flex;*/
}
.thirdcontainer {
    padding: 0.5vw 0.5vw 0.5vw 0.5vw;
    flex-grow: 0;
    flex-shrink: 1;
    overflow: auto;
    margin-left: 30px;
    margin-right: 30px;
    hyphens: auto;
    text-align: justify
}



/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(25,25,25,0.3);
    width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(230,190,190,0.6);
}

@media (min-width: 576px) {
    .vertical {
        font-size: 1.5vw;
    }
}

@media (min-width: 768px) {
    .vertical {
        font-size: 1.2vw;
    }
}

@media (min-width: 992px) {
    .vertical {
        font-size: 1.2vw;
    }
}

@media (min-width: 1200px) {
    .vertical {
        font-size: 1vw;
    }
}

@media (min-width: 1800px) {
    .vertical {
        font-size: 0.8vw;
    }
}
