.infocontainer {
    position: fixed;
    top: 50px;
    background-color: #222;
    color: lightgray;
    left: 60px;
    width: 500px;
    height: auto;
    max-height: 70%;
    z-index: 100100;
    overflow: auto;
    padding: 0px 20px 20px 20px;
}

.infocontainer a:hover {
    color: white;
    font-weight: bold;
}

.lightboxInfoBackground {
    position: fixed;
    top: 50px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100040;
    background-color: red;
    opacity: 0;
}

.lightboxInfoSafe {
    position: fixed;
    top: 50px;
    left: 10px;
    width: 600px;
    height: 100%;
    z-index: 100045;
    background-color: green;
    opacity: 0.0;
}

.lightboxInfopanel {
    position: fixed;
    top: 50px;
    background-color: rgbA(0,0,0,0.0);
    left: 15px;
    width: 50px;
    height: 100px;
    opacity: 0.9;
    z-index: 100050;
}
