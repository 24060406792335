.lineContainer {
    position: fixed;
    width: 33vw;
    top: 50px;
    height: 60px;
    z-index: 102;
}
.dashedline {
    padding-top: 0px;
    height:1px;
    background:
            repeating-linear-gradient(to right,
            lightgrey 0,lightgrey 8px,
            transparent 8px,transparent 13px
            /*lightgrey 13px,lightgrey 15px,*/
            /*transparent 15px,transparent 20px*/
            )
}

.trackControlCircle {
    fill: white;
    stroke: white;
}

.trackControlCircle:hover {
    fill: red;
    stroke: red;
    /*!*cursor: pointer;*!*/
}

.storytrackerContainer {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 33vw;
    height: 50px;
    top: 0;
    left:0;
    padding-left: 1vw;
    padding-top: 10px;
    padding-bottom: 10px;
    /*background-color: #808080;*/
    background-color: #9598A7;
    opacity: 1;
    z-index: 100;
}

.firstStorycontainer {
    padding-top: 60px;
    flex-grow: 3;
    flex-shrink: 3;
}

.normpar {
    margin-left: 1.4em;
    margin-right: 1.4em;
    hyphens: auto;
    text-align: justify
}


.storytrackerItem {
    display: flex;
    align-items: center;
}

.trackicon {
    top: 0;
    flex-basis: 5%;
}

.tracktext {
    font-size: 1.1em;
    flex-basis: auto;
    padding-right: 20px;
    /*align-self: center;*/
    /*margin-right: 20px;*/
}

.tracklane {
    /*align-self: center;*/
    margin-top: 0.5vh;
    justify-content: center;
    flex-basis: 50%;
}

.jumpMark {
    /*background-color: greenyellow;*/
    padding-top: 20px;
}

.jumptext {
    fill: white;
    font-weight: bold;
    text-anchor:left;
    dominant-baseline: middle;
    font-size: 1.0em;
}

.externalImagesContainer {
    margin: 25px 5px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}


.itemsExtImage{
    flex-basis: 3vw;
    flex-grow: 8;
    cursor: pointer;
    /*margin-right: 1vw;*/
    transition: padding 0.5s ease;
    padding: 0px;
}

.dbImagesContainer {
    margin: 25px 5px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.itemsImage{
    flex-basis: 3vw;
    flex-grow: 8;
    cursor: pointer;
    /*margin-right: 1vw;*/
    transition: 0.5s ease;
    padding: 0px;
}

.itemsImage:active{
    padding: 10px;
}

.figureCaption {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px 50px 0px 50px;
    font-weight: lighter;
    font-size: 0.9em;
    font-style: italic;
}

.figureCaptionMuseum {
    margin: 0px 50px 0px 50px;
    text-align: center;
    font-weight: lighter;
    font-size: 0.9em;
    font-style: italic;
    /*border: 2px solid yellow;*/
}


.museumdesc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    padding: 0px 10px 0px 10px;
    font-weight: lighter;
    font-size: 0.9em;
    font-style: italic;
    border-left: 2px solid rgba(250, 250, 250, 0.1);
}

.link:hover {
    color: white;
    font-weight: 500;
}

a:hover {
    color: white !important;
    font-weight: 500;
    text-decoration: underline !important;
}


.museumdescelem {
    padding: 5px 0px 0px 0px;
}

.verticalStory {
    z-index: 100;
    position:fixed;
    top: 0;
    left: 0;
    /*right: -10vh;*/
    width: 33vw;
    height: 100vh;
    color: white;
    padding: 0px 10px 10px 10px;
    margin-top: 0vh;
    margin-left: 0;
    margin-right: 0vw;
    overflow: auto;
    font-size: 0.8vw;
}

@media (min-width: 576px) {
    /*.vertical {*/
    /*    width: 40vw;*/
    /*}*/
    .thirdcontainer {
        margin-left: 5px;
        margin-right: 5px;
    }
    .verticalStory {
        font-size: 1.5vw;
    }
    .figureCaption {
        font-size: 1.2vw;
    }
}

@media (min-width: 768px) {
    /*.vertical {*/
    /*    width: 30vw;*/
    /*}*/
    .verticalStory {
        font-size: 1.2vw;
    }
    .figureCaption {
        font-size: 0.9vw;
    }
}

@media (min-width: 992px) {
    .thirdcontainer {
        margin-left: 1vw;
        margin-right: 1vw;
    }
    .verticalStory {
        font-size: 1.2vw;
    }
    .figureCaption {
        font-size: 0.9vw;
    }
}

@media (min-width: 1200px) {
    .verticalStory {
        font-size: 1vw;
    }
    .figureCaption {
        font-size: 0.9vw;
    }
}

@media (min-width: 1800px) {
    .verticalStory {
        font-size: 0.8vw;
    }
    .figureCaption {
        font-size: 0.75vw;
    }
}

/*.ril__navButtonNext {*/
/*    right: 60px;*/
/*    background: rgba(90, 90, 90, 0.5)*/
/*    url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="34"><path d="M 1 30 L 15 15 L 1 1" stroke-opacity="0.7" fill="transparent" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" style="stroke-opacity: 1;"></path></svg>') no-repeat center;*/
/*}*/
